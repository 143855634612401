
import { Print } from '@mui/icons-material';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);
 

const GetDataUser = async () => {
    try {
      
        const { data, error } = await supabase
        .from('user')
        .select('*, penugasan: kota(nama_kota)')
        if(error){
            console.log(error)
        }

        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};
const GetDataUserByid = async (id) => {
    try {
      
        const { data, error } = await supabase
        .from('user')
        .select()
        .eq('id',id)
        if(error){
            console.log(error)
        }
        console.log("WKWKWKW",data);

        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};

const CreateUser = async (nama, username, password, penugasan) => {
    try {
      const user =  localStorage.getItem("userData") ;
      console.log(user.toString, "WKWKWKWKWK");
      const { error } = await supabase.from('user').insert([
        {
          nama,
          username,
          password: password,
          penugasan,
          created_by:user.id
        },
      ]);
  
      if (error) {
        throw error;
      }
  
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };
const DeleteUser = async (id) => {
    try {
        const { error } = await supabase
        .from('user')
        .delete()
        .eq('id', id)
  
      if (error) {
        throw error;
      }
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };
const EditUser = async (id, nama, username, penugasan,penugasanOld) => {
    try {
        const { error } = await supabase
        .from('user')
        .update({ 
            nama,
            username,
            penugasan,
         })
        .eq('id', id);
        if(penugasan!== penugasanOld){
          console.log("JALAN");
          const{data,error:error2} =await supabase.from('mutasi').insert([
            {
              'user_id':id,
              'kota_lama': penugasanOld,
              'kota_baru': penugasan
            },
          ]);

          console.log(error2);
        }
        
  
      if (error) {
        throw error;
      }
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };
  

export { GetDataUser, CreateUser, DeleteUser, EditUser, GetDataUserByid};
