import React, { useEffect, useState } from "react";

import { Card, CardContent, Box, Typography, Button, Pagination, Select, MenuItem} from "@mui/material";

import ListTopicDiscussionTable from "./element/TopicDIscussionTable.js";
import { GetDatalokasi } from "./lokasiServices.js";
import { GetDataKota } from "./kotaServices.js";

const TopicDiscussionTable = () => {
  const [TopicData, setTopicData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isLoading,setIsLoading]= useState(false);
  const [isdelete, setIsdelete]= useState(false);
  const [dataKota, setDataKota] = useState([]);
  const [assignment, setAssignment] = useState("");

  const fetchKota = async () => {
    const data = await GetDataKota();
    setDataKota(data);
    setAssignment(data[0].id)
    console.log(data);
  };

  useEffect(() => {
    fetchKota();
},[]);
 
  const handleAssignmentChange = (event) => {
    setAssignment(event.target.value);
  };

  if(!localStorage.getItem("authToken")){
    window.location='/login'
  }
  useEffect(() => {
    setIsLoading(true);
    const delayTimeout = setTimeout(() => {
      fetchDiscusData(page,search);
    }, 500);

    return () => clearTimeout(delayTimeout); 
  }, [isdelete,assignment]);

  const fetchDiscusData= async (p,s) => {
    try {
      const response = await  GetDatalokasi(assignment);
      if(response){
      setTopicData(response);
      setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching news data:", error);
      setIsLoading(false);
    }
  };
  const handleSearchChange = (Topic) => {
    setSearch(Topic.target.value);
    setPage(1);
  };
  const handleDeleteDiscus=()=>{
    setIsdelete(!isdelete);
  }
  const handelAdd=()=>{
    window.location = '/#/form-layouts/form-discus'
  }
  return (
        <Box>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Data Lokasi</Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={handelAdd} 
              >
                Tambah Data
              </Button>
            </CardContent>
            <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            maxWidth: 300, 
          }}
        >
             <Select
              id="Nama-Kota"
              value={assignment}
              onChange={handleAssignmentChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select Kota
              </MenuItem>
              {dataKota.map((kota) => (
                <MenuItem key={kota.id} value={kota.id}>
                  {kota.nama_kota}
                </MenuItem>
              ))}
            </Select>
        </Box>
            <Box
              sx={{
                overflow: {
                  xs: "auto",
                  sm: "unset",
                },
              }}
            >
              <ListTopicDiscussionTable topics={TopicData} loading={isLoading} onDeleteDiscus={handleDeleteDiscus}/>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <Pagination
                count={totalPages} 
                page={page}
                onChange={(event, page) => setPage(page)}
                variant="outlined"
                color="primary"
              />
            </Box>
          </Card>
      </Box>
  );
};

export default TopicDiscussionTable;
