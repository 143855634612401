
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);




const GetDataKotaByid = async (id) => {
    try {
        const { data, error } = await supabase
        .from('kota')
        .select()
        .eq('id',id)
        if(error){
            console.log(error)
        }

        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};
const GetDataKota = async () => {
    try {
        const { data, error } = await supabase
        .from('kota')
        .select()
        if(error){
            console.log(error)
        }

        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};

const CreateKota = async (nama_kota) => {
    try {
     
      const { error } = await supabase.from('kota').insert([
        {
          nama_kota
        },
      ]);
  
      if (error) {
        throw error;
      }
  
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };
const DeleteKota = async (id) => {
    try {
        const { error } = await supabase
        .from('kota')
        .delete()
        .eq('id', id)
  
      if (error) {
        throw error;
      }
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };

const EditKota = async (id, nama_kota) => {
    try {
        const { error } = await supabase
        .from('kota')
        .update({ 
            nama_kota,
         })
        .eq('id', id)
  
      if (error) {
        throw error;
      }
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };
  

export {GetDataKota, GetDataKotaByid, CreateKota,EditKota, DeleteKota};
