
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);




const GetDatalokasiByid = async (id) => {
    try {
        const { data, error } = await supabase
        .from('lokasi')
        .select()
        .eq('id',id)
        if(error){
            console.log(error)
        }
        console.log(data)
        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};
const GetDatalokasi = async (kota) => {
    try {
      const { data, error } = await supabase
      .from('lokasi')
      .select('*, kota: kota(nama_kota)')
      .eq('kota',kota)
        if(error){
            console.log(error)
        }
        console.log(data)
        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};

const Createlokasi = async (nama, latitude, longitude, kota) => {
  try {
    const { data: insertData, error: insertError } = await supabase
      .from('lokasi')
      .insert([
        {
          nama,
          "longitude ":longitude,
          "latitude ":latitude,
          kota,
        },
      ]).select();

    if (insertError) {
      throw insertError;
    }

    const insertedId = insertData[0].id;
    console.log(insertData)


    const { data: alarmData, error: insertError2 } = await supabase
      .from('alarm')
      .insert([
        {
          lokasi_id: insertedId,
          is_bahaya: false,
          status: 'AMAN',
        },
      ]);
     
    if (insertError2) {
      throw insertError2;
    }

    return alarmData;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};



const Deletelokasi = async (id) => {
    try {
        const { error } = await supabase
        .from('lokasi')
        .delete()
        .eq('id', id)
  
      if (error) {
        throw error;
      }
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };

const Editlokasi = async (id, nama, longitude, latitude, kota) => {
    try {
      console.log(id);
        const { error } = await supabase
        .from('lokasi')
        .update({ 
            nama,
            'longitude ': longitude,
            'latitude ':latitude,
            kota
         })
        .eq('id', id)
  
      if (error) {
        throw error;
      }
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };
  

export {GetDatalokasi, GetDatalokasiByid, Createlokasi,Editlokasi, Deletelokasi};
