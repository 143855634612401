import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress
} from "@mui/material";

  
const LaporanTable = ({laporans,loading}) => {
  const handleEditClick = (topicId) => {
    window.location=`#/form-layouts/edit-form-discus/${topicId}`;
  };

  
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }
  if (laporans.length === 0 || laporans == null || !laporans) {
    return (
      <TableHead>
        <TableRow>
          <TableCell >
            <Typography>Data Kosong</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else {
    return (
      <Box
        sx={{
          wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal', 
          overflowX: "auto",
          maxWidth: "100%", 
        }}
      >
    <Table
    aria-label="simple table"
    sx={{
       wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
      mt: 3,
    }}
  >
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            No
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            nama Petugas
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            kota
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            image
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            longitude
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            latitude
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            aksi
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {laporans.map((topic,index) => (
        <TableRow key={topic.title}>
          <TableCell>
            <Typography
              sx={{
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
             {index + 1}
            </Typography>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                    fontWeight: "600",
                  }}
                >
                  {topic.user_id.nama}
                </Typography>
               
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                display: "flex",
                alignItems: "center",
              }}
            >
              
                <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.lokasi_id.nama}
                </Typography>
               
              </Box>
          </TableCell>
          <TableCell>
            <img
              src={topic.bukti_image}
              alt={topic.nama} 
              style={{ maxWidth: '100px', maxHeight: '100px' }} 
            />
          </TableCell>
          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic['longitude']}
                </Typography>
          </TableCell>
          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                    fontWeight: "600",
                  }}
                >
                  {topic['latitude']}
                </Typography>
          </TableCell>
          
          <TableCell align="right">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick(topic.id)}
            >
              Lihat
            </Button>
            
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  </Box>
    );
  }
};

export default LaporanTable;
