import Swal from "sweetalert2";

const notifError = (msg) => {
    const toast = Swal.mixin({
        toast: true,
        top: "50%",
        transform: "translateY(-50%)",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        color: "#ffffff",
        background: "#EA4654",
        icon: "error",
        cancelButtonColor: "#ffffff",
    });
    toast.fire({
        title: msg,
    });
};

const notifWarning = (msg) => {
    const toast = Swal.mixin({
        toast: true,
        top: "50%",
        transform: "translateY(-50%)",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        color: "#ffffff",
        background: "#f09000",
        icon: "error",
        cancelButtonColor: "#ffffff",
    });
    toast.fire({
        title: msg,
    });
};

const notifSucces = (msg) => {
    const toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        color: "#ffffff",
        background: "#28a745",
        icon: "success",
        cancelButtonColor: "#ffffff",
    });
    toast.fire({
        title: msg,
    });
};

export { notifError, notifSucces, notifWarning };
