import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);

const handleLogin = async (email, password) => {
    try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email: email,
          password: password,
        })
        if (error) {
          throw error;
        }
        const token = data.session.access_token;
        const user = data.user;
        localStorage.setItem('authToken', token);
        localStorage.setItem('userData', user);
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};
const handleLogOut = async () => {
    try {
       const { error } = await supabase.auth.signOut()
        if (error) {
          throw error;
        }
        localStorage.removeItem("authToken");
        localStorage.removeItem("userData");
        window.location = '/#/tables/news-table';
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};

export { handleLogin, handleLogOut };
