
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);




const GetDatalaporanByid = async (id) => {
    try {
        const { data, error } = await supabase
        .from('laporan')
        .select()
        .eq('id',id)
        if(error){
            console.log(error)
        }
        console.log(data)

        return data;
      } catch (error) {
        console.error('Error while get data detail:', error.message);
      } 
};
const GetDatalaporan = async () => {
    try {
      const { data, error } = await supabase
      .from('laporan')
      .select('*, user_id:user(nama),lokasi_id:lokasi(nama)')
        if(error){
            console.log(error)
        }
        console.log(data)
        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};



export {GetDatalaporan, GetDatalaporanByid};
