
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);




const GetDataMutasiByid = async (id) => {
    try {
        const { data, error } = await supabase
        .from('mutasi')
        .select('*, kota_lama:kota(nama), kota_baru:kota(nama)')
        .eq('id',id)
        if(error){
            console.log(error)
        }

        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};
const GetDataMutasi = async () => {
  try {
    const { data, error } = await supabase
      .from('mutasi')
      .select(`
        *,
        user: user_id (nama),
        kota_lama: kota_lama (nama_kota),
        kota_baru: kota_baru (nama_kota)
      `);

    if (error) {
      console.error(error);
      return null; // Handle the error appropriately
    }

    console.log(data);
    return data;
  } catch (error) {
    console.error('Error while fetching mutasi data:', error.message);
    return null; // Handle the error appropriately
  }
};


  

export {GetDataMutasi, GetDataMutasiByid};
