import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; 
import { Grid } from "@mui/material";
import NewsEditForm from "./element/NewsFormEdit";
import { GetDataKotaByid } from "../tables/kotaServices";

const EditNewsFormPage = () => {
  const { newsId } = useParams(); 
  const [newsdata, setNewsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetDataKotaByid(newsId)
        setNewsData(response[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [newsId]);

  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        {newsdata !== null && <NewsEditForm News={newsdata} />}
      </Grid>
    </Grid>
  );
};

export default EditNewsFormPage;
