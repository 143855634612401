import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress
} from "@mui/material";
import { DeleteKota } from "../kotaServices";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";

const months = [
  "Januari", "Februari", "Maret", "April", "Mei", "Juni",
  "Juli", "Agustus", "September", "Oktober", "November", "Desember"
];

const NewsTable = ({ News, onDeleteNews ,loading })  => {
  const handleEditClick = (newsId) => {
    window.location=`#/form-layouts/edit-form-news/${newsId}`;
  };

  const handleDeleteClick = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this User?")) {
      try {
        await DeleteKota(eventId);
       await notifSucces("Kota Berhasil Di Hapus");
        onDeleteNews();
      } catch (error) {
        console.error("Error while deleting User:", error);
        notifWarning("Kota Gagal Di Hapus");
      }
    }
  };
  
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (News.length === 0 || News == null || !News) {
    return (
      <TableHead>
        <TableRow>
          <TableCell >
            <Typography>Data Kosong</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else {
    return (
      <Box
        sx={{
           wordWrap: 'break-word',
                      
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
          overflowX: "auto",
          maxWidth: "100%", 
        }}
      >
        <Table
          aria-label="simple table"
          sx={{
             wordWrap: 'break-word',
                      
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
            mt: 3,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell >
                <Typography color="textSecondary" variant="h6">
                  No
                </Typography>
              </TableCell>
              <TableCell >
                <Typography color="textSecondary" variant="h6">
                  Nama Kota
                </Typography>
              </TableCell>
              <TableCell >
                <Typography color="textSecondary" >
                  created At
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="textSecondary" variant="h6">
                  aksi
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {News.map((news, index) => (
              <TableRow key={news.id}>
                <TableCell >
                  <Typography
                    sx={{
                       wordWrap: 'break-word',
                      
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                      fontSize: "15px",
                    }}
                  >
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell >
                  <Typography
                    variant="h6" >
                    {news.nama_kota}
                  </Typography>
                </TableCell>
                <TableCell >
                  <Typography variant="h6">
                    {new Date(news.created_at).getDate()} {months[new Date(news.created_at).getMonth()]} {new Date(news.created_at).getFullYear()}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEditClick(news.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteClick(news.id)}
                    sx={{
                       wordWrap: 'break-word',
                      
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',  ml: 2 }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  }
};

export default NewsTable;
