import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; 
import { Grid } from "@mui/material";
import DiscussionEditForm from "./element/DiscussionFormEdit";
import { GetDatalokasiByid } from "../tables/lokasiServices";

const EditDiscussionFormPage = () => {
  const { discussionId } = useParams();
  const [Discussiondata, setDiscussionData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetDatalokasiByid(discussionId)
        setDiscussionData(response[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [discussionId]);

  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        {Discussiondata !== null && <DiscussionEditForm Lokasi={Discussiondata} />}
      </Grid>
    </Grid>
  );
};

export default EditDiscussionFormPage;
