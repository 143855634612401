import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { EditKota } from "../../tables/kotaServices";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";

const KotaEditForm = ({News}) => {
  const [nama, setnama] = useState("");
  const [id,setId]= useState();
  const handlenamaChange = (event) => {
    setnama(event.target.value);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      await EditKota(id,nama);
      
      notifSucces("Kota Berhasil Di Edit");
      window.location = '/#/tables/news-table'
    }catch(e){
      notifWarning("Kota Gagal Di Edit");
    }
  };
  
  useEffect(() => {
    if (News) {
      setnama(News.nama_kota);
      setId(News.id);
    }
  }, [News]);

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            padding: "15px 30px",
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Input Kota
            </Typography>
          </Box>
        </Box>
        <CardContent
          sx={{
            padding: "30px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <TextField
              id="nama-text"
              label="nama"
              type="text"
              variant="outlined"
              fullWidth
              value={nama}
              required
              onChange={handlenamaChange}
              sx={{
                mb: 2,
              }}
            />
           
            <div>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default KotaEditForm;
