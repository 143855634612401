import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { CreateUser} from "../../tables/userServices";
import { GetDataKota } from "../../tables/kotaServices";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";


const UserForm = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [assignment, setAssignment] = useState("");
  const [dataKota, setDataKota] = useState([]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAssignmentChange = (event) => {
    setAssignment(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      await CreateUser(name, username, password, assignment);
      
      notifSucces("User Berhasil Di simpan");
      window.location = '/#/tables/events-table'
    }catch(e){
      notifWarning("User Gagal Di simpan");
    }
  };

  const fetchKota = async () => {
    const data = await GetDataKota();
    setDataKota(data);
  };

  useEffect(() => {
    fetchKota();
  }, []);

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            padding: "15px 30px",
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Input User
            </Typography>
          </Box>
        </Box>
        <CardContent
          sx={{
            padding: "30px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <TextField
              id="name-text"
              label="Name"
              type="text"
              variant="outlined"
              fullWidth
              value={name}
              required
              onChange={handleNameChange}
              sx={{
                mb: 2,
              }}
            />
            <TextField
              id="username-text"
              label="Username"
              type="text"
              variant="outlined"
              fullWidth
              value={username}
              required
              onChange={handleUsernameChange}
              sx={{
                mb: 2,
              }}
            />
            <TextField
              id="password-text"
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              required
              onChange={handlePasswordChange}
              sx={{
                mb: 2,
              }}
            />
            <Select
              id="assignment-select"
              value={assignment}
              onChange={handleAssignmentChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select Assignment
              </MenuItem>
              {dataKota.map((kota) => (
                <MenuItem key={kota.id} value={kota.id}>
                  {kota.nama_kota}
                </MenuItem>
              ))}
            </Select>
            <div>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserForm;
