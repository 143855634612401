import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from "@mui/material";

const MutasiTabel = ({ users,loading }) => {
  ;
  const months = [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];
  

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }
  if (users == null || !users) {
    return (
      <TableHead>
        <TableRow>
          <TableCell >
            <Typography>Data Kosong</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else {
    return (
      <Box
        sx={{
           wordWrap: 'break-word', 
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
          overflowX: "auto",
          maxWidth: "100%", 
        }}
      >
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Nama
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
             Kota Lama
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="textSecondary" variant="h6">
              Kota Baru
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="textSecondary" variant="h6">
              Tanggal
            </Typography>
          </TableCell>
         
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((User,index) => (
          <TableRow key={User.id}>
            <TableCell>
              <Box
                sx={{
                  wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {User.user.nama}
                  </Typography>
                 
                </Box>
              </Box>
            </TableCell>
           <TableCell>
           <Typography variant="h6">
               {User.kota_lama.nama_kota }
              </Typography>
           </TableCell>
            <TableCell align="center">
              <Typography variant="h6">
               {User.kota_baru.nama_kota}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h6">
              {new Date(User.created_at).getDate()} {months[new Date(User.created_at).getMonth()]} {new Date(User.created_at).getFullYear()}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
  );
}
};

export default MutasiTabel;
