import React, { useEffect, useState } from "react";
import {
  Card,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { GetDataKota } from "../../tables/kotaServices";
import L from 'leaflet'; // Import pustaka Leaflet

import 'leaflet/dist/leaflet.css'; 
import { Editlokasi } from "../../tables/lokasiServices";


const DiscussionEditForm = ({Lokasi}) => {
  const [title, setTitle] = useState("");
  const [dataKota, setDataKota] = useState([]);
  const [assignment, setAssignment] = useState("");
  const [position, setPosition] = useState(null); 
  const [isLoading, setIsLoading] = useState(false); 


  const handleAssignmentChange = (event) => {
    setAssignment(event.target.value);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const response = await Editlokasi(Lokasi.id,title,position.lat,position.lng,assignment);

      window.location = '/#/tables/topic-discussion-table'
      setIsLoading(false)
    } catch (error) {
      console.error("Error while adding Topic Discussion:", error);
      setIsLoading(false)
    }
  };
  
  const fetchKota = async () => {
    const data = await GetDataKota();
    setDataKota(data);
    setAssignment(Lokasi.kota)
    console.log(data);
  };

  useEffect(() => {
    fetchKota();
    setTitle(Lokasi.nama)
    setPosition({lat:Lokasi['latitude '],lng:Lokasi['longitude ']});
},[Lokasi]);

 
useEffect(() => {
    fetchKota();
    const map = L.map('map').setView([Lokasi['latitude '], Lokasi['longitude ']], 18);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    const handleMapClick = (e) => {
      setPosition(e.latlng);
    };

    map.on('click', handleMapClick);

    return () => {
      map.remove(); // Membersihkan peta saat komponen di-unmount
    };
  }, []);
  
  if(isLoading){
    return <div>
      Loading
    </div>
  }

  return (
    <div>
      <Card>
        <Box
          sx={{
            padding: "15px 30px",
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Input Topik Diskusi
            </Typography>
          </Box>
        </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              id="title-text"
              label="Nama Lokasi"
              type="text"
              variant="outlined"
              fullWidth
              value={title}
              required
              onChange={handleTitleChange}
              sx={{
                mb: 2,
              }}
            />
            <Select
              id="Nama-Kota"
              value={assignment}
              onChange={handleAssignmentChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select Kota
              </MenuItem>
              {dataKota != null&& dataKota.map((kota) => (
                <MenuItem key={kota.id} value={kota.id}>
                  {kota.nama_kota}
                </MenuItem>
              ))}
            </Select>
            <div id="map" style={{ height: '500px', width: '100%' }} />
            <div className="selected-coordinates">
        <label htmlFor="latitude">Latitude:</label>
        <input
          type="text"
          id="latitude"
          value={position ? position.lat : ''}
          readOnly
        />
        <label htmlFor="longitude">Longitude:</label>
        <input
          type="text"
          id="longitude"
          value={position ? position.lng : ''}
          readOnly
        />
      </div>
            
            <div>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </div>
          </form>
          </Card>
    </div>
  );
};

export default DiscussionEditForm;
