import React, { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  TextField,
  Button,
} from "@mui/material";
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import { handleLogin } from "./AuthServices";

const LoginPage = () => {
 
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State untuk menampilkan loading

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    setLoading(true); 
   await handleLogin(Email,Password)
    setLoading(false); 
    window.location.reload();

  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          width: "500px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent
          sx={{
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 4,
            }}
          >
            <AccountBoxRoundedIcon
              sx={{
                fontSize: 100,
                color: "gray", // Warna ikon
              }}
            />
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              id="Email-text"
              label="Email"
              type="text"
              variant="outlined"
              fullWidth
              value={Email}
              required
              onChange={handleEmailChange}
              sx={{
                mb: 2,
              }}
            />
            
            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              onChange={handlePasswordChange}
              value={Password}
              autoComplete="current-password"
              variant="outlined"
              fullWidth
              required
              sx={{
                mb: 2,
              }}
            />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type="submit" color="primary" variant="contained" disabled={loading}>
                {loading ? 'Loading...' : 'Login'}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginPage;
